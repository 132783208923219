/* OnlinePayments.css */
.container {
  margin: 20px;
}

.payment-list {
  list-style: none;
  padding: 0;
}

.payment-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-item span {
  cursor: pointer;
  color: blue;
}

.payment-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.payment-form label {
  margin-bottom: 10px;
}

.payment-form input {
  padding: 8px;
  margin-bottom: 10px;
}

.payment-form button {
  padding: 10px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
}
