@import "~font-awesome/css/font-awesome.min.css";

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos para ChatComponent.css */
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.chat-messages {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;

  input {
    flex: 1;
    margin-right: 10px;
  }

  button {
    cursor: pointer;
  }
}

